import { slide as Menu } from 'react-burger-menu'
import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'
import { getUserTheme, useWindowSize } from '@platform/utils'
import { Button, Link, Logo } from '@platform/ui-kit'
import { XIcon, Menu2Icon } from '@icons'
import variables from '@styles/variables.module.scss'
import classnames from 'classnames'

import styles from './index.module.scss'

type ItemListTypes = {
  label: string
  href: string
  activeHref: string
}

type BurgerMenuTypes = {
  navItems: ItemListTypes[]
  burgerIconClassName: string
}

type OpenMenuType = {
  isOpen: boolean
}

export const BurgerMenu = ({ navItems, burgerIconClassName }: BurgerMenuTypes) => {
  const { windowWidth } = useWindowSize()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const screenSize = 703
  const theme = getUserTheme()
  const { asPath } = useRouter()

  const handleStateChange = (state: OpenMenuType) => {
    setIsMenuOpen(state.isOpen)
  }

  const handleCloseMenu = () => {
    setIsMenuOpen(false)
  }

  const buttonStyles = useCallback(
    (activeHref: string) => ({
      className: classnames(styles['navigation-buttons'], {
        [styles['active-route-button']]: asPath === activeHref,
      }),
    }),
    [asPath]
  )

  if (!windowWidth) {
    return null
  }

  const menuStyle = {
    bmOverlay: {
      background: variables[`${theme}-color-overlay`],
      height: '100vh',
    },
    bmMenuWrap: { width: windowWidth <= screenSize ? '100%' : '352' },
  }

  return (
    <Menu
      onStateChange={handleStateChange}
      right={windowWidth ? windowWidth <= screenSize : false}
      className={styles['menu-wrapper']}
      menuClassName={styles['menu']}
      burgerButtonClassName={classnames(styles['open-menu-button'], styles[burgerIconClassName])}
      crossButtonClassName={styles['close-menu-button']}
      overlayClassName={styles['menu-overlay']}
      customBurgerIcon={<Menu2Icon />}
      customCrossIcon={<XIcon />}
      isOpen={isMenuOpen}
      styles={menuStyle}
      onClose={handleCloseMenu}
    >
      <>
        <Link
          aria-label="Olby logo"
          href="/"
          className={styles['menu-logo']}
          prefetch={false}
          onClick={handleCloseMenu}
        >
          <Logo variant="primary" />
        </Link>
        <ul className={styles['menu-navigation-wrapper']}>
          {navItems.map(({ label, href }) => (
            <li key={label} className={styles['navigation-items']}>
              <Button
                variant="ghost"
                onClick={handleCloseMenu}
                href={href}
                {...buttonStyles(href)}
                prefetch={false}
                size="large"
              >
                {label}
              </Button>
            </li>
          ))}
          <div className={styles['menu-buttons']}>
            <Button size="large" href="/talent">
              Join Olby
            </Button>
            <Button size="large" variant="primary" href="/companies">
              Hire Olby
            </Button>
          </div>
        </ul>
      </>
    </Menu>
  )
}

export default BurgerMenu
